<template>
  <mdb-container>
    <div class="text-right">
      <router-link to="/admin/partnerManage">
        <mdb-btn outline="info" size="md" class="my-3" icon="arrow-left">
          뒤로가기
        </mdb-btn>
      </router-link>
    </div>
    <mdb-card>
      <h3 class="card-header unique-color white-text text-center font-weight-bold text-uppercase py-2 ">협력사 수정</h3>
      <div class="card-body">

        <label for="orderNumber" class="grey-text">순번</label>
        <input type="text" id="orderNumber" class="form-control" v-model="order_number" placeholder="0">
        <br>
        <label for="partnerName" class="grey-text">기업명</label>
        <input type="text" id="partnerName" class="form-control" v-model="partner_name">
        <br>
        <label for="homepage" class="grey-text">홈페이지 주소</label>
        <input type="text" id="homepage" class="form-control" v-model="homepage">
        <mdb-select multiple @getValue="getSelectValue" :options="sel_fields" label="분야를 선택하세요." resetBtn/>
        <div>
          <img :src="img" alt="img" style="max-width: 150px">
          <mdb-file-input btnColor="info" @getValue="getFileInputValue" img/>
        </div>
        <div class="text-center mt-1">
          <mdb-btn outline="success" @click="onUpdateData()" icon="pen">수정</mdb-btn>
        </div>
        <!-- Default form contact -->
      </div>
      <!-- Card body -->
    </mdb-card>
  </mdb-container>

</template>

<script>
import {mdbCard, mdbSelect, mdbContainer, mdbBtn, mdbFileInput} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: "PartnerUpdate",
  data() {
    return {
      fileList: [],
      id: this.$route.query.id,
      logo: "",
      img: "",
      partner_name: "",
      order_number: "",
      homepage: "",
      sel_fields: [
        {text: '드론', value: '드론'},
        {text: '웹', value: '웹'},
        {text: '앱', value: '앱'},
        {text: '펌웨어', value: '펌웨어'},
        {text: '하드웨어', value: '하드웨어'}
      ],
      fields: "",
    }
  },
  components: {
    mdbCard,
    mdbSelect,
    mdbContainer,
    mdbBtn,
    mdbFileInput,
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    onLoadData() {
      const db = firebase.firestore();
      const self = this;
      db.collection("Partners")
          .doc(self.id).get().then(function (querySnapshot) {
        const _t = querySnapshot.data();
        self.order_number = _t.order_number;
        self.partner_name = _t.partner_name;
        self.homepage = _t.homepage;
        self.img = _t.logo;
        const fieldsData = [];
        const fieldNames = ["드론", "웹", "앱", "펌웨어", "하드웨어"];
        for (let i = 0; i < fieldNames.length; i++) {
          if (_t.fields.includes(fieldNames[i])) {
            fieldsData.push({text: fieldNames[i], value: fieldNames[i], selected: true},)
          } else {
            fieldsData.push({text: fieldNames[i], value: fieldNames[i]},)
          }
        }
        self.sel_fields = fieldsData;
      });
    },

    getSelectValue(value) {
      const self = this;
      self.fields = value;
    },
    getFileInputValue(val) {
      const self = this;
      self.fileList = val;
    },
    onUpdateData() {
      const db = firebase.firestore();
      const self = this;
      if (self.fileList.length === 0) {
        const data = {
          partner_name: self.partner_name,
          order_number: self.order_number,
          homepage: self.homepage,
          fields: self.fields,
          reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
        }
        db.collection("Partners").doc(self.id)
            .update(data)
            .then(() => {
              console.log("Document successfully updated!");
              alert('협력사 등록이 완료되었습니다.');
              this.$router.push('/admin/partnerManage').catch((err) => {
                console.log(err)
              })
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
      } else {
        const storage = firebase.storage();
        const file = self.fileList[0];
        const storageRef = storage.ref();
        const savePath = storageRef.child('partners/' + file.name);
        const upload = savePath.put(file);

        upload.on('state_changed',
            // 변화시 동작하는 함수
            null,
            //에러시 동작하는 함수
            (error) => {
              console.error('실패사유는', error);
            },
            // 성공시 동작하는 함수
            () => {
              upload.snapshot.ref.getDownloadURL().then((url) => {

                if(self.order_number === ''){
                  self.order_number = 0
                }

                const data = {
                  partner_name: self.partner_name,
                  order_number: self.order_number,
                  homepage: self.homepage,
                  fields: self.fields,
                  reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
                  logo: url,
                }
                db.collection("Partners").doc(self.id)
                    .update(data)
                    .then(() => {
                      console.log("Document successfully updated!");
                      alert('협력사 등록이 완료되었습니다.');
                      this.$router.push('/admin/partnerManage').catch((err) => {
                        console.log(err)
                      })
                    })
                    .catch((error) => {
                      console.error("Error updating document: ", error);
                    });
              });
            }
        );
      }
    },
  },
}
</script>

<style scoped>

</style>
